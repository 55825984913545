export default {
  labels: {
    productor_or_group: "Produtor/Grupo de Produtores",
    id_productor_or_group: "ID",
    id_producer_unit: "ID UP",
    type: "Tipo",
    harvest: "Safra",
    cpf_cnpj: "CPF/CNPJ",
    cpf_cnpj_of: "CPF/CNPJ - {type}",
    cpf_cnpj_productor_group: "CPF/CNPJ do Produtor/Grupo",
    cpf: "CPF",
    cnpj: "CNPJ",
    status: "Status {certificationType}",
    responsible: "Responsável {certificationType}",
    responsibles: "Responsável(eis) {certificationType}",
    field_responsible: "{field} Responsável {programType}",
    field_responsible_leader: "{field} Responsável Líder {programType}",
    responsible_leader: "Responsável Líder {programType}",
    unity_productive: "Unidade Produtiva",
    city: "Município",
    registration: "Matrícula | Matrículas",
    manage_invite: "Gerenciar Convite",
    record: "registro | registros",
    show_register: "Exibindo {totalPerPage} {record} de {totalItems}",
    producer: "Produtor",
    producer_group: "Grupo de Produtores",
    search_producer: "Busque pelo nome, CPF/CNPJ ou e-mail do produtor",
    search_producer_group: "Busque pelo Nome do Grupo de Produtores",
    producer_group_email: "E-mail do Grupo.",
    email: "E-mail",
    composition: "Composição",
    cancel_invite: "Cancelar convite",
    remove: "Remover",
    close: "Fechar",
    harvest_certification: "Safra de certificação",
    edit_harvest_certification: "Editar Safra de Certificação",
    confirm_action: "Tem certeza que deseja realizar esta operação?",
    select_producer_group: "Selecionar Produtor ou Grupo de Produtores",
    name_of: "Nome do {name}",
    requester: "Solicitante",
    datetime_type: "Data/Hora {type}",
    datetime: "Data/Hora",
    responsible_opnion: "Responsável parecer",
    uf: "UF",
    reason_request: "Motivo da solicitação",
    current_status: "Status atual",
    new_exception_request_title: "Solicitar Exceção ABR/SBRHVI",
    detail_producer_or_producer_group: "Detalhes do Produtor/Grupo",
    detail_request: "Detalhes da Solicitação",
    accountable_notion: "Resp. Parecer",
    fantasy_name_of: "Nome Fantasia - {type}",
    corporate_name_of: "Razão Social - {type}",
    legal_personality: "Personalidade Jurídica",
    gs1_plus_digit: "Dig. Extensão + GS1",
    category: "Categoria",
    full_name: "Nome Completo",
    fantasy_name: "Nome Fantasia",
    corporate_name: "Razão Social",
    detail_production_unit: "Dados da Unidade Produtiva",
    registration_data: "Dados de Cadastro",
    registered_enrollments: "Matrículas Cadastradas",
    data_cottons: "Dados de Algodoeira(s)",
    name: "Nome",
    association: "Associação",
    general_data: "Dados Gerais",
    localization_data: "Dados de Localização",
    cep: "CEP",
    address: "Endereço",
    district: "Bairro",
    latitude_dms: "Latitude (DMS)",
    longitude_dms: "Longitude (DMS)",
    data_production_or_producer_group: "Dados do Produtor/Grupo de Produtores",
    producer_or_group: "Produtor/Grupo",
    data_area: "Dados de área",
    total_area: "Área Total (Ha)",
    cotton_area: "Área de Algodão (H)",
    environmental_protection_area: "Área de Proteção Ambiental (Ha)",
    registry: "Cartório",
    state_registration: "Inscrição Estadual",
    area_ha: "Área (Ha)",
    benefit: "Beneficiamento",
    self_and_others: "Próprio + Terceiros",
    id: "ID",
    code_gs1: "Código GS1",
    digit_extension: "Dígito  Extensão",
    physical_person_legal_person: "Pessoa Física/Pessoa Jurídica",
    data_export: "Exportação de dados"
  },
  buttons: {
    cancel: "Cancelar",
    submit: "Salvar",
    next: "Próximo",
    previus: "Voltar",
    search: "Pesquisar",
    clear: "Limpar",
    new_invite: "Realizar Convite",
    cancel_selection: "Cancelar seleção",
    confirm_selection_up: "Selecionar Unidade Produtiva",
    confirm: "Confirmar",
    refuse_program: "Recusar {program}",
    exception_request: "Solicitar Exceção {program}",
    send_request: "Enviar solicitação",
    close: "Fechar",
    select: "Selecionar",
    selected: "Selecionado",
    cancel_request: "Cancelar solicitação",
    request_review_status: "Solicitar revisão de Status",
    reprove_exception_request: "Reprovar Exceção ABR/SBRHVI",
    aprove_exception_request: "Aprovar Exceção ABR/SBRHVI",
    loadmore: "Carregar mais"
  },
  text: {
    // Geral
    titulo_produtores_grupo_produtores_convidados:
      "Produtores/Grupo de Produtores convidados",
    informativo_safra:
      "A partir de 2021, todos os produtores que aderirem ao ABR, ABR/BCI, automaticamente, aderem ao SBRHVI por prazo indeterminado.",
    titulo_botoes_selecionar_sistemas:
      "Selecione os sistemas nos quais você deseja realizar o convite.",
    titulo_selecionar_produtor_grupo:
      "1- Selecione o Produtor ou Grupo de Produtores",
    titulo_selecione_produtor: "Selecione o Produtor",
    titulo_selecione_grupo_produtor: "Selecione o Grupo de Produtores",
    confirmar_unidades_produtivas_pertencentes_produtor_grupo_para_convite:
      "2- Confirme as Unidades Produtivas pertencentes ao Produtor/Grupo que serão convidadas para o ABR/BCI",
    passos_sistemas: {
      1: "Definir Sistemas",
      2: "Definir Convites",
      3: "Confirmação"
    },
    nenhum_registro_selecionado: "Nenhum",
    nenhum_registro_selecionado_tabelas: "Nenhum registro selecionado",
    nenhum_registro_encontrado_tabelas: "Nenhum registro encontrado",
    nenhum_registro_encontrado: "Nenhum registro de {nome} encontrado",
    nenhum_registro_encontrado_por_tipo: "Nenhum {tipo} encontrado",
    alterar_produtor_grupo: "Alterar Produtor/Grupo",
    adicionar_produtor_grupo: "Adicionar Produtor/Grupo",
    produtor_grupo_selecionado: "Produtor/Grupo selecionado",
    modal_selecionar_produtor_grupo:
      "Selecione o tipo de cadastro a ser convidado",
    selecionar_responsavel_up_programa:
      "3 - Selecione um responsável para responder pelas Unidades Produtivas no Programa ABR/BCI.",
    selecionar_responsavel_sbrhvi:
      "1- Selecione um Responsável Líder para responder pelo Produtor/Grupo no Programa SBRHVI.",
    informativo_apos_definir_up_produtor_grupo_para_convite:
      "Após selecionar, você poderá definir as Unidades Produtivas do Produtor ou Grupo de Produtores desejado.",
    adicionar_responsavel: "Adicionar Responsável",
    adicionar_responsavel_lider: "Adicionar Responsável Líder",
    alterar_responsavel_lider: "Alterar Responsável Líder",
    busca_produtor_grupo: "Busque pelo CPF, nome ou e-mail do usuário",
    informativo_usuario_nao_encontrado_busca:
      "Não encontrou o usuário desejado? Adicione um novo no botão Novo usuário",
    novo_convidado: "Novo convidado",
    titulo_novo_convidado: "Selecionar/Cadastrar Convidado",
    informativo_adicionar_responsavel_sbrhvi:
      "Você deverá selecionar o Responsável Líder pelo SBRHVI. Este perfil poderá acrescentar outros Responsáveis no sistema.",
    informativo_possui_responsavel_sbrhvi: [
      "Após algum dos Responsáveis ABR/BCI aceitar o convite, o Responsável Líder pelo SBRHVI receberá acesso ao sistema.",
      "Caso algum dos Responsáveis ABR/BCI recusar o convite para a adesão aos Programas, o Responsável Líder pelo SBRHVI receberá o Convite para a adesão especificamente para este sistema."
    ],
    informativo_possui_responsavel_sbrhvi_sistema:
      "Este Produtor/Grupo selecionado já possui um Responsável Líder para o acesso ao Programa SBRHVI. Alterações ou inclusões de novos Responsáveis devem ser realizadas diretamente no sistema SBRHVI.",
    unidades_produtivas_selecionadas: "Unidade(s) Produtiva(s) selecionada(s)",
    informativo_revise_informacoes_conclusao:
      "Revise as informações antes de concluir o convite.",
    // ABR
    responsavel_abr: "Responsável selecionado para o ABR/BCI",
    responsaveis_abr: "Responsáveis selecionado(s) para o ABR/BCI",
    titulo_responsaveis_selecionados_abr: "Responsável(eis) para o ABR/BCI",

    // SBRHVI
    responsavel_sbrhvi: "Responsáveis selecionado(s) para o SBRHVI",
    responsavel_lider_sbrhvi: "Responsável Líder selecionado para o SBRHVI",
    titulo_responsaveis_selecionados_sbrhvi:
      "Responsável(eis) Líder(es) para o SBRHVI",

    informativo_existe_responsavel_lider:
      "O Produtor/Grupo já é participante SBRHVI.",

    erro_interno: "Ocorreu um erro interno. Tente novamente!",

    titulo_unidades_produtivas_convidadas_abr_bci:
      "Unidades Produtivas convidadas no ABR/BCI",

    informativo_acesso_unidade_produtiva_safra_vigente:
      "Se o convite for aceito até 30 de junho, o usuário terá acesso até o fim da safra vigente ao sistema ABR",

    titulo_responsavel_lider_sbrhvi: "Responsável Líder pelo Programa SBRHVI",

    titulo_demais_responsaveis_sbrhvi: "Demais Responsáveis SBRHVI",

    titulo_nenhum_responsavel_cadastrado: "Nenhum responsável cadastrado",

    informativo_alterar_responsavel_sbrhvi_antes_aceite_convite:
      "Só é possível alterar o Responsável Líder, até o aceite do Convite. Após o aceite, alterações em relação ao Responsável Líder devem ser feitas diretamente no Sistema SBRHVI.",

    titulo_unidade_produtiva_responsaveis_convidados: "Responsáveis Convidados",

    confirm_action: "Após confirmar, essa operação não poderá ser desfeita.",

    success_cancel_invite: "Convite cancelado com sucesso",

    success_invite: "Convite {situacao} com sucesso",

    term_generated_success: "Termo gerado com sucesso!",

    mail_resend_success: "E-mail reenviado com sucesso",

    responsavel_alterado_sucesso: "Responsável alterado com sucesso",

    safra_certificacao_atualizada_sucesso:
      "Safra de certificação atualizada com sucesso.",

    informativo_editar_safra_certificacao_dados_invalidos:
      "Não é possível editar a Safra de Certificação. Já foi aceito o convite para esta Unidade Produtiva.",

    informativo_selecionar_up_dados_invalidos:
      "Você precisa definir uma Safra de Certificação para esta Unidade Produtiva para poder selecioná-la.",

    titulo_recusar_programa: "Recusar Programa ABR/BCI e/ou SBRHVI",

    titulo_qual_programa_recusar: "Qual Programa você deseja recusar?",

    titulo_desejo_recusar_programa: "Desejo recusar o Programa  {programa}.",

    titulo_desejo_recusar_somente_programa:
      "Desejo recusar somente o Programa  {programa}.",

    info_recusar_programa_abrbci_e_sbrhvi:
      "Se, posteriormente, você desejar convidar alguma outra Unidade Produtiva pertencente a este Produtor/Grupo de Produtores, será mantida obrigatória a regra: só é possível aderir ao Programa ABR/BCI aceitando participar, também, do Programa SBRHVI.",

    info_recusar_programa_abrbci_aceitar_sbrhvi:
      "Utilize esta opção caso você deseje recusar a participação no Programa ABR/BCI mas irá aceitar participar do SBRHVI.",

    info_recusar_programa_sbrhvi:
      "Não é possível aderir ao Programa ABR/BCI sem aderir ao Programa SBRHVI para este Produtor/Grupo de Produtores.",

    confirmar_recusar_convite:
      "Você tem certeza de que deseja recusar o Convite para os Programa {programa}?",

    confirm_cancel_exception_request:
      "Você tem certeza de que deseja cancelar a solicitação de exceção?",

    confirm_send_opinion_exception_request:
      "Você tem certeza de que deseja {action} para essa solicitação de exceção?",

    info_confirmacao_recusar_convite_abrbci:
      "Ao recusar o convite para o Programa ABR/BCI, o Responsável Líder SBRHVI será convidado para responder ao convite do SBRHVI.",

    info_definir_parecer_bci:
      "Você precisa definir a opção pelo Licenciamento BCI para prosseguir",

    mandatory_exception_requests:
      "Solicitações de Exceção de obrigatoriedade SBRHVI",

    field_not_found: "---",
    text_info_new_exception_requests:
      "Apenas na Safra 2020/2021, em caráter de exceção, é possível solicitar à Abrapa autorização para que Produtores/Grupo de Produtores façam adesão apenas ao Programa ABR sem fazê-la ao Programa SBRHVI.",

    request_review_status: "Solicitar revisão de status",

    terms_accepted_request_status_review:
      "Declaro estar ciente de que ao enviar a solicitação, a situação será atualizada para Em Análise.",

    exception_request_register_success:
      "Solicitação de exceção criada com sucesso",

    select_type_of_new_exception_request:
      "Selecione o tipo de cadastro a ser pesquisado",

    info_exception_request_effect:
      "Ao solicitar Exceção do Convite para o SBRHVI, na Safra 2020/2021, o Produtor/Grupo será certificado ABR e não terá os resultados de HVI publicados em sua rastreabilidade.",

    info_exception_request_producer_not_found:
      "Caso não encontre o Produtor/Grupo desejado, verifique se estes já estão habilitados em SINDA, se foram convidados para adesão ABR/SBRHVI, se já deram o aceite em convites ou se já estão em processo de solicitação de Exceção.",

    message_review_request_success:
      "Solicitação de revisão registrada com sucesso",

    message_request_success_of_type: "Solicitação {type} com sucesso",

    producer_with_exempt_sbrhvi: "Produtor/Grupo com exceção ABR/SBRHVI.",
    productive_unit_without_owner:
      "Não é possível efetivar o Convite caso a Unidade de Beneficiamento não possua ao menos 1(um) proprietário vinculado."
  },
  terms: {
    bci_warning:
      "Caso a unidade produtiva/grupo/produtor desista da opção pelo licenciamento BCI ou opte por fazê-lo, após a submissão do presente termo de adesão, poderá alterar sua opção no sistema ABR (via web) até o dia 30/06 do ano vigente.",
    sbrhvi_producer:
      "Pelo presente termo, {nome_produtor}, brasileiro, agricultor, portador do Carteira de Identidade nº {rg}, e inscrito no {cpf_or_cnpj} sob o nº. {cpf_cnpj}, associado à {razao_social_associacao_estadual} - {sigla_associacao_estadual}, domiciliado na {endereco}, {cidade}/{uf}, {nome_produtor} firma sua adesão em caráter irrevogável e irretratável ao PROGRAMA STANDARD BRASIL  (“SBRHVI”), declarando para os devidos fins que:",
    sbrhvi_group_1:
      "Pelo presente termo o grupo de cotonicultores, {nome_grupo}, associado à {razao_social_associacao_estadual} - {sigla_associacao_estadual}, devidamente formado pelos cotonicultores:",
    sbrhvi_group_2:
      "devidamente inscritos com os seus respectivos CPFs ou no CNPJ/MF, firma sua adesão em caráter irrevogável e irretratável ao Programa Standard Brasil HVI (SBRHVI), declarando para os devidos fins que:"
  }
};
